<template>
    <div class="dish_search">
        <!-- 头部 -->
        <my-header :menuList="state.menuList"></my-header>
        <!-- 子路由 -->
        <router-view></router-view>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import MyHeader from './MyHeader.vue';

const state = reactive({
    menuList: [
        { name: "历史报告", path: '/dishsearch/history' },
        { name: "自定义大区", path: '/dishsearch/areatemplist' },
        { name: '历史合并菜', path: '/dishsearch/historymerge' }
    ]
})
</script>

<style lang="scss" scoped>
.dish_search {
    padding-top: 60px;
}
</style>