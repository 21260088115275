<template>
  <header>
    <div class="left_box">
      <div class="logo">
        <p>菜品搜索</p>
      </div>
      <div class="menus">
        <ul>
          <li v-for="item in menuList" :key="item.path" :class="$route.path === item.path ? 'active' : ''"
            @click="onChange(item)">
            {{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="right_box">
      <p>{{ state.userName }}</p>
      <p>|</p>
      <span @click="outLoginFun">退出</span>
    </div>
  </header>
</template>

<script setup>
import { reactive } from "vue";
import { getStorage } from "@/utils";
import outLoginHook from '@/utils/outLoginHook.js';
import { useRouter } from 'vue-router'

const $router = useRouter()

defineProps({
  menuList: {
    type: Array,
    default: [],
  }
});

const state = reactive({
  userName: getStorage("name") || "admin",
});

// 点击改变
function onChange(e) {
  $router.push(e.path);
}

// 退出登录
const { outLoginFun } = outLoginHook()
</script>


<style lang="scss" scoped>
header {
  height: 60px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 10px 10px #f2f2f2;

  .left_box {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
      height: 100%;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6387e1;

      p {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 2px;
        line-height: 60px;
      }

      img {
        height: 100%;
      }
    }

    .menus {
      ul {
        display: flex;

        li {
          margin-left: 30px;
          font-size: 16px;
          color: #818a9b;
          cursor: pointer;
          user-select: none;

          &.active {
            color: #6387e1;
            font-weight: 800;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .right_box {
    display: flex;
    align-items: center;

    p {
      color: #80899b;
      font-size: 16px;
      padding: 0 8px;
    }

    span {
      color: #80899b;
      font-size: 15px;
      padding: 0 8px;
      padding-right: 20px;
      cursor: pointer;

      &:hover {
        color: #6387e1;
      }
    }
  }
}
</style>